<template>
  <div class="indexbox">
    <nav-bar title="好物推荐" left-arrow />
    <div class="body">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
        <div class="goodlist">
          <div class="item" v-for="val in list" :key="val.id" @click="router(`detail?id=${val.id}`)">
            <img v-lazy="val?.goods_img[0]?.url" alt="">
            <div class="cont">
              <p class="ti one ">{{ val.name }}</p>
              <div class="price one">{{ val.price }}</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
  import Web3 from 'web3'
  export default {
    data() {
      return {
        page: 1,
        list: [],
        finished: false,
        loading: false,
        usdt_num: 0,
      }
    },
    created() {

    },
    methods: {
      onLoad() {
        const page_num = this.page++
        this.$get({
          url: "/goods/list",
          data: {
            is_recomd: 1,
            page: page_num,
          },
          success: (res) => {
            if (res.data && res.data.data.length == 0) {
              this.finished = true
            } else {
              if (page_num == 1) {
                this.list = res.data.data
              } else {
                this.list = [...this.list, ...res.data.data]
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
    },
  }
</script>
<style lang="less" scoped>
  .indexbox {
    width: 100%;
    padding: 0 0 60px;
    .head {
      width: 100%;
      padding: 15px 15px 0;
      background: #fff;
      .swipebox {
        width: 100%;
        height: 150px;
        border-radius: 10px;
        overflow: hidden;
        :deep(.van-swipe-item) {
          width: 100%;
          height: 100%;
          >img,
          >video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        :deep(.van-swipe__indicators) {
          .van-swipe__indicator {
            width: 6px;
            height: 2px;
          }
          .van-swipe__indicator--active {
            width: 20px;
            height: 2px;
          }
        }
      }
      .noticebox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        img {
          width: 16px;
          height: 16px;
        }
        .notice {
          width: 77%;
          height: 30px;
          .tit {
            width: 100%;
            height: 100%;
            line-height: 30px;
            color: #333333;
            font-size: 14px;
          }
        }
        .r {
          width: 12%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          color: #999;
          font-size: 12px;
        }
      }
    }
    .body {
      width: 100%;
      padding: 15px;
      .t {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        a {
          width: 166px;
          height: 72px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
        }
      }
      .tit {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
        line-height: 1;
        .l {
          display: flex;
          align-items: center;
          img {
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
          color: #333333;
          font-size: 15px;
          font-weight: 600;
        }
        .r {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          color: #999999;
          font-size: 12px;
          line-height: 1;
          padding: 0 0 0 10px;
        }
      }
      .goodlist {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        .item {
          width: 167px;
          background: #FFFFFF;
          border-radius: 8px;
          overflow: hidden;
          margin-right: 11px;
          margin-bottom: 11px;
          &:nth-child(2n) {
            margin-right: 0;
          }
          img {
            width: 100%;
            height: 165px;
            border-radius: 8px 8px 0px 0px;
          }
          .cont {
            width: 100%;
            padding: 0 8px 10px;
            line-height: 1;
            .ti {
              width: 100%;
              padding: 10px 0;
              color: #333333;
              font-size: 15px;
              font-weight: 550;
            }
            .price {
              width: 100%;
              color: @theme;
              font-size: 15px;
              font-weight: 550;
            }
          }
        }
      }
    }
  }
</style>