import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import { Locale } from 'vant'
import enUS from 'vant/lib/locale/lang/en-US'
import zhCN from 'vant/lib/locale/lang/zh-CN';

import enLocale from './en'
import zhLocale from './zh'

const messages = {
  en: {
    ...enUS,
    ...enLocale
  },
  zh: {
    ...zhCN,
    ...zhLocale
  },
}

const i18n = new VueI18n({
  locale: localStorage.getItem(`${process.env.VUE_APP_NAME}lang`) || 'zh',//默认中文 l('lang') || 'zh',
  messages,
  silentTranslationWarn: false
})

// 更新vant组件库本身的语言变化，支持国际化
function vantLocales(lang) {
  localStorage.setItem(`${process.env.VUE_APP_NAME}lang`, lang)
  if (lang == 'en') {
    Locale.use(lang, enUS)
  } else {
    Locale.use(lang, zhCN)
  }
}

export { i18n, vantLocales }