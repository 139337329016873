<template>
  <div class='indexbox'>
    <nav-bar title="邀请好友" left-arrow bg-color="none" left-color="#fff" title-color="#fff" />
    <div class="bodybox">
      <div class="info">
        <p class="t">邀请链接：</p>
        <div class="p" @click="copyData(info.url)">
          <p class="one">{{ info.url }}</p>
          <van-button>复制</van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        info: {},
      }
    },
    created() {
      this.getdata()
    },
    methods: {
      getdata() {
        this.$get({
          url: "/member/getInviteUrl",
          loading: true,
          success: (res) => {
            this.info = res.data
          },
          tip: () => { },
        });
      },
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    min-height: 100vh;
    background: url('../../../assets/img/bk@2x.png') no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    position: relative;
    .bodybox {
      width: 100%;
      padding: 0 40px 0 45px;
      position: absolute;
      bottom: 200px;
      left: 0;
      .info {
        width: 100%;
        .t {
          width: 100%;
          color: #fff;
          font-size: 16px;
          margin-bottom: 8px
        }
        .p {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #fff;
          border-radius: 6px;
          >p {
            width: 80%;
            padding: 0 10px;
            color: #333333;
            font-size: 13px;
            font-weight: 550;
          }
        }
      }
    }
  }
</style>