<template>
  <div class='indexbox'>
    <nav-bar title="商城" :fixed="false" />
    <van-search v-model="keyword" clearable placeholder="搜你想搜的名称" @search="search" />
    <van-tabs v-model="active" color="#9F62EE" title-inactive-color="#333333" sticky @click="onClick">
      <van-tab :title="item.cate_name" v-for="item in tablist" :key="item.id">
        <div class="body">
          <van-swipe class="swipe" indicator-color="#9F62EE">
            <van-swipe-item v-for="(val, index) in navlist" :key="index">
              <van-grid :column-num="5" :border="false">
                <van-grid-item v-for="item in val" :key="item.id" @click="getcatId(item)">
                  <van-image :src="item.img[0].url" />
                  <p class="one">{{ item.cate_name }}</p>
                </van-grid-item>
              </van-grid>
            </van-swipe-item>
          </van-swipe>
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0"
            :immediate-check="false">
            <div class="goodlist">
              <div class="item" v-for="val in list" :key="val.id" @click="router(`detail?id=${val.id}`)">
                <img v-lazy="val?.goods_img[0]?.url" alt="">
                <div class="cont">
                  <p class="ti one ">{{ val.name }}</p>
                  <div class="price one">{{ val.price }}</div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        keyword: '',
        cate_id: '',
        active: 0,
        tablist: [],

        page: 1,
        list: [],
        finished: false,
        loading: false,
      }
    },
    computed: {
      navlist() {
        const { children } = this.tablist[this.active]
        if (children.length > 10) {
          const arr = []
          for (let i = 0; i < children.length; i += 10) {
            arr.push(children.slice(i, i + 10))
          }
          return arr
        } else {
          return [children]
        }
      }
    },
    created() {
      this.getdata()
    },
    methods: {
      getdata() {
        this.$get({
          loading: true,
          url: "/goodsCate/list",
          success: (res) => {
            this.tablist = res.data
            if (this.session.get('shopIndex')) {
              if (Number(this.session.get('shopIndex')) > Number(this.tablist.length)) {
                this.cate_id = this.tablist[0].id
                this.active = 0
                this.onLoad(0)
              } else {
                this.active = Number(this.session.get('shopIndex'))
                this.cate_id = this.tablist[this.active].id
                this.onLoad(this.active)
              }
            } else {
              this.cate_id = this.tablist[0].id
              this.active = 0
              this.onLoad(0)
            }
          },
          tip: () => { },
        });
      },
      search() {
        this.page = 1
        this.list = []
        this.finished = false
        this.onLoad()
      },
      getcatId(data) {
        this.cate_id = data.id
        this.page = 1
        this.list = []
        this.finished = false
        this.onLoad()
      },
      onClick(index) {
        this.session.set('shopIndex', index)
        this.cate_id = this.tablist[this.active].id
        this.page = 1
        this.list = []
        this.finished = false
        this.onLoad()
      },
      onLoad() {
        const page_num = this.page++
        this.$get({
          url: "/goods/list",
          loading: true,
          data: {
            page: page_num,
            is_recomd: 0,
            cate_id: this.cate_id,
            keywords: this.keyword,
          },
          success: (res) => {
            if (res.data && res.data.data.length == 0) {
              this.finished = true
            } else {
              if (page_num == 1) {
                this.list = res.data.data
              } else {
                this.list = [...this.list, ...res.data.data]
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    min-height: 100vh;
    background: #fff;
    padding: 0 0 50px;
    :deep(.van-tabs) {
      .van-tab--active {
        font-weight: 600;
      }
    }
    .body {
      width: 100%;
      padding: 5px 15px;
      :deep(.van-grid) {
        .van-grid-item {
          width: 20%;
          .van-grid-item__content {
            padding-top: 10px;
            padding-bottom: 10px;
            .van-image {
              width: 51px;
              height: 51px;
              border-radius: 50%;
              overflow: hidden;
            }
            p {
              width: 100%;
              text-align: center;
              margin-top: 5px;
              color: #333333;
              font-size: 12px;
            }
          }
        }
      }
      .swipe {
        width: 100%;
        padding: 0 0 15px;
        :deep(.van-swipe__indicators) {
          background: #F8F8F8;
          border-radius: 10px;
          .van-swipe__indicator:not(:last-child) {
            margin: 0;
          }
          .van-swipe__indicator {
            width: 10px;
            height: 6px;
            border-radius: 10px;
            background: #F8F8F8;
          }
          .van-swipe__indicator--active {
            width: 15px;
            height: 6px;
          }
        }
      }
      .goodlist {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        .item {
          width: 167px;
          background: #FFFFFF;
          border-radius: 8px;
          overflow: hidden;
          margin-right: 11px;
          margin-bottom: 11px;
          &:nth-child(2n) {
            margin-right: 0;
          }
          img {
            width: 100%;
            height: 165px;
            border-radius: 8px 8px 0px 0px;
          }
          .cont {
            width: 100%;
            padding: 0 8px 10px;
            line-height: 1;
            .ti {
              width: 100%;
              padding: 10px 0;
              color: #333333;
              font-size: 15px;
              font-weight: 550;
            }
            .price {
              width: 100%;
              color: @theme;
              font-size: 15px;
              font-weight: 550;
            }
          }
        }
      }
    }
  }
</style>