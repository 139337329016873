<template>
  <div class="indexbox">
    <nav-bar title="首页" />
    <div class="head">
      <van-swipe class="swipebox" autoplay="5000" indicator-color="#9F62EE">
        <van-swipe-item v-for="item in banner" :key="item.id">
          <img :src="item?.img[0]?.url" alt="">
        </van-swipe-item>
      </van-swipe>
      <div class="noticebox">
        <img src="@/assets/img/gg.png" alt="" @click="router('news')">
        <van-swipe class="notice" :autoplay="4000" vertical :show-indicators="false">
          <van-swipe-item v-for="val in notice" :key="val.id" @click="router(`newdel?id=${val.id}`)">
            <p class="one tit">{{ val.title }}</p>
          </van-swipe-item>
        </van-swipe>
        <div class="r" @click="router('news')">
          <span>更多</span>
          <van-icon name="arrow" size="12" color="#999999" />
        </div>
      </div>
    </div>
    <div class="body">
      <div class="t">
        <router-link to="goodlist">
          <img src="@/assets/img/youxuanhaowu@2x.png" alt="">
        </router-link>
        <div @click="navWealth">
          <img src="@/assets/img/licaizhuanqu@2x.png" alt="">
        </div>
      </div>
      <div class="tit" @click="router('goodlist')">
        <div class="l">
          <img src="@/assets/img/tuijian@2x.png" alt="">
          <span>好物推荐</span>
        </div>
        <div class="r">
          <span>更多</span>
          <van-icon name="arrow" size="12" color="#999999" />
        </div>
      </div>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
        <div class="goodlist">
          <div class="item" v-for="val in list" :key="val.id" @click="router(`detail?id=${val.id}`)">
            <img v-lazy="val?.goods_img[0]?.url" alt="">
            <div class="cont">
              <p class="ti one ">{{ val.name }}</p>
              <div class="price one">{{ val.price }}</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>

    <van-popup v-model="show" round :style="{ width: '90%' }" :close-on-click-overlay="false">
      <div class="codebox">
        <img src="@/assets/img/tips@2x.png" alt="">
        <p class="t">请先绑定大区</p>
        <p>输入密码</p>
        <van-field v-model="pass" clearable placeholder="请输入大区密码" />
        <van-button block @click="bindTeam" :loading="bloading">确认</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
  import Web3 from 'web3'
  export default {
    data() {
      return {
        banner: [],
        notice: [],

        page: 1,
        list: [],
        finished: false,
        loading: false,

        code: '',
        token: '',

        show: false,
        pass: '',
        bloading: false,
      }
    },
    created() {
      if (this.$route.query.invite_code) {
        this.code = this.$route.query.invite_code
      }
      if (this.session.get("token")) {
        this.token = this.session.get("token")
      }
      this.getdata()
      this.webLogin()
    },
    methods: {
      getdata() {
        this.$get({
          url: "/banner/list",
          success: (res) => {
            this.banner = res.data;
          },
          tip: () => { },
        });
        this.$get({
          url: "/notice/list?page=1",
          success: (res) => {
            this.notice = res.data.data
          },
          tip: () => { },
        });
      },
      webLogin() {
        this.$nextTick(() => {
          if (!this.token) {
            this.$toast.loading({
              forbidClick: true,
            })
            var time;
            time = setInterval(() => {
              this.address = this.$store.state.wallet.account;
              if (this.$store.state.wallet.account != "") {
                clearInterval(time);
                this.code && console.warn('code', this.code);
                let message = this.getGuid()
                let web3 = new Web3(window.ethereum);
                web3.eth.personal.sign(message, this.address, async (err, res) => {
                  if (res != null && res !== undefined && res !== '') {
                    this.$post({
                      url: "/login",
                      noToken: true,
                      loading: true,
                      data: {
                        account: this.address,
                        msg: message,
                        sign: res,
                        invite_code: this.code
                      },
                      success: (res) => {
                        console.warn("登录成功");
                        this.session.set("token", res.data.token)
                        this.getinfo();
                        this.page = 1
                        this.list = []
                        this.finished = false
                        this.onLoad();
                      },
                      tip: () => { },
                    });
                  } else {
                    this.$toast.clear()
                    this.$dialog.alert({
                      message: '签名失败',
                    }).then(() => {
                      this.session.del("token")
                      window.location.reload();
                    });
                  }
                })
              }
            }, 1000);
          }
          if (this.token) {
            this.getinfo();
          }
        });
      },
      getinfo() {
        this.$get({
          loading: true,
          url: "/member/getMemberInfo",
          success: (res) => {
            this.local.set('team_id', res.data.team_id || 0)
            if (res.data.is_pay_password == 0) {
              this.$dialog.confirm({
                title: '提示',
                message: '请先设置资金密码',
                showCancelButton: false,
              }).then(() => {
                this.router('changepay?type=1')
              })
              return
            }
          },
          tip: () => { },
        });
      },
      onLoad() {
        const page_num = this.page++
        this.$get({
          url: "/goods/list",
          data: {
            is_recomd: 1,
            page: page_num,
          },
          success: (res) => {
            if (res.data && res.data.data.length == 0) {
              this.finished = true
            } else {
              if (page_num == 1) {
                this.list = res.data.data
              } else {
                this.list = [...this.list, ...res.data.data]
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
      navWealth() {
        const team_id = this.local.get('team_id')
        if (team_id == 0) {
          this.show = true
        } else {
          this.$router.replace('wealth')
        }
      },
      bindTeam() {
        if (!this.pass) return this.$toast('请输入大区密码')
        this.bloading = true
        this.$post({
          loading: true,
          url: "/member/bindTeam",
          data: { team_no: this.pass },
          success: (res) => {
            this.$toast({
              message: '绑定成功',
              onClose: () => {
                this.bloading = false
                this.show = false
                this.$router.replace('wealth')
              }
            })
          },
          tip: () => { this.bloading = false },
        });
      }
    },
  }
</script>
<style lang="less" scoped>
  .indexbox {
    width: 100%;
    padding: 0 0 60px;
    .head {
      width: 100%;
      padding: 15px 15px 0;
      background: #fff;
      .swipebox {
        width: 100%;
        height: 150px;
        border-radius: 10px;
        overflow: hidden;
        :deep(.van-swipe-item) {
          width: 100%;
          height: 100%;
          >img,
          >video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        :deep(.van-swipe__indicators) {
          .van-swipe__indicator {
            width: 6px;
            height: 2px;
          }
          .van-swipe__indicator--active {
            width: 20px;
            height: 2px;
          }
        }
      }
      .noticebox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        img {
          width: 16px;
          height: 16px;
        }
        .notice {
          width: 77%;
          height: 30px;
          .tit {
            width: 100%;
            height: 100%;
            line-height: 30px;
            color: #333333;
            font-size: 14px;
          }
        }
        .r {
          width: 12%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          color: #999;
          font-size: 12px;
        }
      }
    }
    .body {
      width: 100%;
      padding: 15px;
      .t {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        a,
        div {
          width: 166px;
          height: 72px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
        }
      }
      .tit {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
        line-height: 1;
        .l {
          display: flex;
          align-items: center;
          img {
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
          color: #333333;
          font-size: 15px;
          font-weight: 600;
        }
        .r {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          color: #999999;
          font-size: 12px;
          line-height: 1;
          padding: 0 0 0 10px;
        }
      }
      .goodlist {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        .item {
          width: 167px;
          background: #FFFFFF;
          border-radius: 8px;
          overflow: hidden;
          margin-right: 11px;
          margin-bottom: 11px;
          &:nth-child(2n) {
            margin-right: 0;
          }
          img {
            width: 100%;
            height: 165px;
            border-radius: 8px 8px 0px 0px;
          }
          .cont {
            width: 100%;
            padding: 0 8px 10px;
            line-height: 1;
            .ti {
              width: 100%;
              padding: 10px 0;
              color: #333333;
              font-size: 15px;
              font-weight: 550;
            }
            .price {
              width: 100%;
              color: @theme;
              font-size: 15px;
              font-weight: 550;
            }
          }
        }
      }
    }
  }
  :deep(.van-popup) {
    background: none;
    padding: 40px 20px 20px;
    img {
      width: 100px;
      height: 100px;
      margin-top: -65px;
    }
    .codebox {
      width: 100%;
      background: url('../../assets/img/kuang@2x.png') no-repeat;
      background-size: 100% 100%;
      padding: 26px 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      p {
        width: 100%;
        color: #333333;
        font-size: 13px;
        font-weight: 550;
      }
      .t {
        text-align: center;
        font-size: 18px;
        margin-bottom: 20px;
      }
      .van-field {
        width: 100%;
        margin: 10px 0 20px;
        background: #F8F8F8;
        border-radius: 3px;
      }
    }
  }
</style>