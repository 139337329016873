<template>
  <div class='indexbox'>
    <nav-bar left-arrow title="公告列表" />
    <div class="list">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
        <div class="item" v-for="(val, index) in list" :key="index" @click="router(`newdel?id=${val.id}`)">
          <p class="two">{{ val.title }}</p>
          <p>{{ val.updated_at }}</p>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        list: [],
        page: 1,
        loading: false,
        finished: false,
      }
    },
    created() { },
    methods: {
      onLoad() {
        let page = this.page++
        this.$get({
          url: "/notice/list",
          data: {
            page,
          },
          success: (res) => {
            if (!res.data || res.data.data.length == 0) {
              this.finished = true
            } else {
              var list = res.data.data
              if (page == 1) {
                this.list = list
              } else {
                for (var i in list) {
                  this.list.push(list[i])
                }
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
    },
  }
</script>
<style lang='less' scoped>
  .list {
    width: 100%;
    padding: 10px 15px;
    .item {
      width: 100%;
      background: #FFFFFF;
      border-radius: 6px;
      padding: 15px;
      font-size: 15px;
      margin-bottom: 15px;
      p {
        font-weight: 600;
        margin-bottom: 4px;
        &:nth-child(2) {
          color: #999999;
          font-size: 12px;
          font-weight: 500;
          line-height: 1;
        }
      }
    }
  }
</style>