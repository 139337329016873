<template>
  <div class='indexbox'>
    <nav-bar left-arrow title="公告详情" />
    <div class="bodybox">
      <p class="tit">{{ info.title }}</p>
      <p class="time">{{ info.updated_at }}</p>
      <div class="msgbox htmlcontentbox" v-html="info.content"></div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        info: {},
      }
    },
    created() {
      this.getdata()
    },
    methods: {
      getdata() {
        this.$get({
          loading: true,
          url: `/notice/info?id=${this.urlQuery().id}`,
          success: (res) => {
            this.info = res.data;
          },
          tip: () => { },
        });
      },
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    min-height: 100vh;
    background: #fff;
    .bodybox {
      width: 100%;
      padding: 10px 15px 40px;
      .tit {
        width: 100%;
        font-size: 21px;
        font-weight: 550;
      }
      .time {
        width: 100%;
        padding: 10px 0;
        color: #999999;
        font-size: 13px;
      }
      .msgbox {
        width: 100%;
        color: #666666;
        font-size: 14px;
      }
    }
  }
</style>