<template>
  <div class='indexbox'>
    <nav-bar title="个人设置" left-arrow />
    <div class="bodybox">
      <div class="info">
        <van-skeleton title :row="3" v-if="show" />
        <label for="upfile" v-if="!show">
          <img :src="`${httpPath}${head_img}`" alt="" class="head">
          <input type="file" id="upfile" style="display: none;" ref="upfile" @change="uploadImages">
        </label>
      </div>
      <div class="cellbox">
        <van-field label="钱包地址" type="textarea" rows="2" autosize :value="info.account" readonly is-link
          @click="copyData(info.account)" />
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        show: false,
        info: {},
        head_img: '',
      }
    },
    created() {
      this.getinfo()
    },
    methods: {
      getinfo() {
        this.show = true
        this.$get({
          loading: true,
          url: "/member/getMemberInfo",
          success: (res) => {
            this.info = res.data
            this.head_img = res.data.head_img
            this.show = false
          },
          tip: () => { this.show = false },
        });
      },
      uploadImages() {
        const dom = this.$refs.upfile
        const data = dom.files[0]
        if (!data) return;
        let file = new FormData()
        file.append("file", data)
        this.$post({
          url: '/upload/local/image',
          loading: true,
          upload: true,
          data: file,
          success: (res) => {
            this.editHeadImg(res.data)
          },
          tip: () => { }
        })
      },
      editHeadImg(url) {
        this.$post({
          url: '/member/editHeadImg',
          loading: true,
          data: {
            head_img: url
          },
          success: (res) => {
            this.head_img = url
          },
          tip: () => { }
        })
      }
    },
  }
</script>
<style lang='less' scoped>
  .bodybox {
    width: 100%;
    padding: 15px;
    .info {
      width: 100%;
      background: #fff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 15px;
      margin-bottom: 15px;
      .head {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
    }
    .cellbox {
      border-radius: 8px;
      overflow: hidden;
    }
  }
</style>