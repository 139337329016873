<template>
  <div class='indexbox'>
    <nav-bar title="资金密码" left-arrow />
    <div class="bodybox">
      <van-field v-model="old_password" clearable type="password" maxlength="6" label="原密码" placeholder="请输入原密码"
        :border="false" v-if="!type" />
      <van-field v-model="password" clearable type="password" maxlength="6" label="新密码" placeholder="请输入新密码"
        :border="false" />
      <van-field v-model="re_password" clearable type="password" maxlength="6" label="确认新密码" placeholder="请再次输入新密码"
        :border="false" />

      <van-button class="btn" block :loading="loading" @click="submit">保存</van-button>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        type: this.urlQuery().type || '',
        old_password: '',
        password: '',
        re_password: '',
        loading: false,
      }
    },
    created() { },
    methods: {
      submit() {
        if (!this.type) {
          if (!this.old_password) return this.$toast('请输入原密码')
        }
        if (!this.password) return this.$toast('请输入新密码')
        if (!this.re_password) return this.$toast('请再次输入新密码')
        if (this.password != this.re_password) return this.$toast('两次密码不一致')
        this.loading = true
        this.$post({
          loading: true,
          url: "/member/editPayPassword",
          data: {
            old_password: this.old_password,
            password: this.password,
            re_password: this.re_password,
          },
          success: (res) => {
            this.$toast({
              message: '保存成功',
              onClose: () => {
                this.loading = false
                this.$router.back()
              }
            })
          },
          tip: () => {
            this.loading = false
          },
        });
      }
    },
  }
</script>
<style lang='less' scoped>
  .bodybox {
    width: 100%;
    padding: 15px 15px 40px;
    :deep(.van-field) {
      border-radius: 6px;
      margin-bottom: 15px;
    }
    .btn {
      margin-top: 100px;
    }
  }
</style>