<template>
  <div class='indexbox'>
    <nav-bar title="充值" left-arrow />
    <div class="body">
      <p class="tit">金额</p>
      <van-field v-model="number" type="number" placeholder="输入充值金额">
        <template #button>
          <p class="p">XW</p>
        </template>
      </van-field>
      <p class="msg">1xw={{ config.xw_price }}token</p>
      <van-field v-model="getnumber" type="number" placeholder="实际到账(自动计算)">
        <template #button>
          <p class="p">token</p>
        </template>
      </van-field>
      <p class="tit">资金密码</p>
      <van-field v-model="password" clearable type="password" maxlength="6" placeholder="请输入资金密码" :border="false" />
      <van-button v-if="config.recharge_address" :loading="loading" block @click="submit">确定</van-button>
      <van-button v-else block disabled>暂未绑定入币钱包地址</van-button>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        config: {},
        number: '',
        password: '',
        loading: false,
      }
    },
    computed: {
      getnumber: {
        get() {
          if (this.number) {
            return Number(Number(this.number) * Number(this.config.xw_price)).toFixed(2)
          } else {
            return ''
          }
        },
      }
    },
    created() {
      this.getinfo()
    },
    methods: {
      getinfo() {
        this.$get({
          loading: true,
          url: "/recharge/getConfig",
          success: (res) => {
            this.config = res.data
          },
          tip: () => { },
        });
      },
      submit() {
        if (!this.number) return this.$toast('输入充值金额')
        if (!this.password) return this.$toast('请输入资金密码')
        this.loading = true
        this.$post({
          url: "/recharge/rechargeDo",
          data: {
            amount: this.number,
            password: this.password,
          },
          success: (res) => {
            this.$toast.loading({
              duration: 0,
              overlay: true
            })
            this.transfer(res.data.id)
          },
          tip: () => {
            this.loading = false
          },
        })
      },
      transfer(id) {
        let that = this
        that.$nextTick(() => {
          that.blockFun
            .transfer("xw", String(that.number), that.config.recharge_address, "ether", false)
            .then((hash) => { that.payOrder(hash, id) }).catch((err) => {
              that.loading = false
              that.$toast(err.msg)
            })
        })
      },
      payOrder(hash, id) {
        this.$post({
          url: "/recharge/rechargeSuccess",
          loading: true,
          data: { hash, id },
          success: (res) => {
            this.number = ''
            this.password = ''
            this.loading = false
            this.$toast('成功')
          },
          tip: () => {
            this.loading = false
          },
        })
      }
    },
  }
</script>
<style lang='less' scoped>
  .body {
    width: 100%;
    padding: 15px 15px 50px;
    .tit {
      width: 100%;
      font-size: 15px;
      font-weight: 550;
    }
    .msg {
      color: @theme;
      font-size: 12px;
      font-weight: 550;
    }
    .p {
      color: @theme;
      font-size: 15px;
      font-weight: 550;
    }
    :deep(.van-field) {
      background: #FFFFFF;
      border-radius: 6px;
      margin: 15px 0;
    }
    :deep(.van-button) {
      margin-top: 200px;
    }
  }
</style>