<template>
  <div class='indexbox'>
    <nav-bar title="理财" :fixed="false" />
    <div class="bodybox">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
        <div class="item" @click="router(`wealthpay?id=${val.id}&price=${val.price}`)" v-for="val in list"
          :key="val.id">
          <img v-lazy="val.img[0].url" alt="">
          <div class="r">
            <p class="n one">{{ val.name }}</p>
            <p class="f p">收益率：<span>{{ val.rate || 0 }}%</span></p>
            <div class="b">
              <p class="p">价格：<span>{{ val.price }}token</span></p>
              <p class="btn">
                <span>立即下单</span>
                <van-icon name="play" size="10" />
              </p>
            </div>
          </div>
        </div>
      </van-list>
    </div>

  </div>
</template>
<script>
  export default {
    data() {
      return {
        page: 1,
        list: [],
        finished: false,
        loading: false,
      }
    },
    created() {
      this.getinfo()
    },
    methods: {
      getinfo() {
        this.$get({
          loading: true,
          url: "/member/getMemberInfo",
          success: (res) => {
            this.local.set('team_id', res.data.team_id || 0)
          },
          tip: () => { },
        });
      },
      onLoad() {
        const page_num = this.page++
        this.$get({
          url: "/invest/list",
          data: {
            page: page_num,
          },
          success: (res) => {
            if (res.data && res.data.data.length == 0) {
              this.finished = true
            } else {
              if (page_num == 1) {
                this.list = res.data.data
              } else {
                this.list = [...this.list, ...res.data.data]
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
    },
  }
</script>
<style lang='less' scoped>
  .bodybox {
    width: 100%;
    min-height: 100vh;
    background: #fff;
    padding: 15px;
    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 6px;
      margin-bottom: 15px;
      >img {
        width: 107px;
        height: 113px;
      }
      .r {
        width: 67%;
        .n {
          width: 100%;
          color: #333333;
          font-size: 15px;
          font-weight: 550;
        }
        .f {
          padding: 5px 0;
        }
        .p {
          color: #333333;
          font-size: 12px;
          span {
            color: #9F62EE;
            font-size: 14px;
            font-weight: 550;
          }
        }
        .b {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 15px 0 0;
          .btn {
            padding: 3px 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 14px 14px 14px 14px;
            img {
              width: 10px;
              height: 10px;
              margin-left: 3px;
            }
            font-size: 10px;
          }
        }
      }
      &:nth-child(3n+1) {
        background: linear-gradient(136deg, #DDF0FF 0%, #BDC5FF 100%);
        .p {
          span {
            color: #5C5EE6;
          }
        }
        .btn {
          color: #5C5EE6;
          background: linear-gradient(136deg, #DDF0FF 0%, #BDC5FF 100%);
          border: 1px solid #BEC8FF;
        }
      }
      &:nth-child(3n+2) {
        background: linear-gradient(136deg, #DDF3FF 0%, #D1BDFF 100%);
        .p {
          span {
            color: #CB5CE6;
          }
        }
        .btn {
          color: #CB5CE6;
          background: linear-gradient(136deg, #DDF3FF 0%, #D1BDFF 100%);
          border: 1px solid #D5BEFF;
        }
      }
      &:nth-child(3n+3) {
        background: linear-gradient(136deg, #FFF2DD 0%, #FFBDBD 100%);
        .p {
          span {
            color: #E65C67;
          }
        }
        .btn {
          color: #E65C67;
          background: linear-gradient(136deg, #FFF2DD 0%, #FFBDBD 100%);
          border: 1px solid #FFBEBE;
        }
      }
    }
  }
</style>