<template>
  <div class="indexbox">
    <nav-bar title="地址管理" left-arrow @click-right="change">
      <template #right>
        <p class="tit" v-if="!isChange">管理</p>
        <p class="tit" v-if="isChange">退出管理</p>
      </template>
    </nav-bar>
    <div class="list">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
        <div class="item" :class="{ active: val.is_default == 1 }" v-for="val in list" :key="val.id">
          <div class="box" @click="edit(val)">
            <div class="info">
              <div class="top">
                <span class="name">{{ val.name }}</span>
                <span class="tel">{{ val.mobile }}</span>
                <span class="d" v-if="val.is_default == 1">默认</span>
              </div>
              <div class="detail">{{ val.area.name_path }} </div>
              <div class="detail">{{ val.detail }} </div>
            </div>
            <div class="r">
              <img src="@/assets/img/bianji@2x.png" alt="">
            </div>
          </div>
          <div class="chang" v-if="isChange">
            <div class="l" @click="changeDefault(val)">
              <img src="@/assets/img/xz@2x.png" alt="" v-if="val.is_default">
              <img src="@/assets/img/zc@2x.png" alt="" v-else>
              <span>默认</span>
            </div>
            <van-button size="mini" @click="deleteAddress(val)">删除</van-button>
          </div>
        </div>
      </van-list>
    </div>
    <van-goods-action>
      <div class="btns">
        <van-button block to="editaddres">添加地址</van-button>
      </div>
    </van-goods-action>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        page: 1,
        list: [],
        finished: false,
        loading: false,

        isChange: false,
      }
    },
    created() { },
    methods: {
      onLoad() {
        const page_num = this.page++
        this.$get({
          url: "/memberAddress/list",
          data: {
            page: page_num,
          },
          success: (res) => {
            if (res.data && res.data.data.length == 0) {
              this.finished = true
            } else {
              if (page_num == 1) {
                this.list = res.data.data
              } else {
                this.list = [...this.list, ...res.data.data]
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
      change() {
        this.isChange = !this.isChange
      },
      edit(data) {
        this.router(`editaddres?id=${data.id}`)
      },
      changeDefault(data) {
        if (data.is_default == 1) return;
        this.$dialog.confirm({
          title: '提示',
          message: '确认要设为默认地址吗？',
        }).then(() => {
          this.$post({
            url: '/memberAddress/setDefault',
            loading: true,
            data: { id: data.id },
            success: () => {
              this.$toast({
                message: '设置成功',
                onClose: () => {
                  this.page = 1
                  this.finished = false
                  this.onLoad()
                }
              })
            },
            tip: () => { }
          })
        }).catch(() => { });
      },
      deleteAddress(data) {
        this.$dialog.confirm({
          title: '提示',
          message: '确认要删除此地址吗？',
        }).then(() => {
          this.$post({
            url: '/memberAddress/del',
            loading: true,
            data: { id: data.id },
            success: () => {
              this.$toast({
                message: '删除成功',
                onClose: () => {
                  this.page = 1
                  this.finished = false
                  this.onLoad()
                }
              })
            },
            tip: () => { }
          })
        }).catch(() => { });
      },
    },
  }
</script>
<style scoped lang="less">
  .tit {
    color: @theme;
  }
  .list {
    width: 100%;
    padding: 15px 15px 80px;
    .item {
      width: 100%;
      background: #FFFFFF;
      border-radius: 6px;
      margin-bottom: 15px;
      padding: 10px 15px;
      .box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .info {
          width: 90%;
          .top {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 4px;
            .name {
              font-size: 15px;
              font-weight: 600;
              color: #000000;
              padding-right: 10px;
            }
            .tel {
              font-size: 12px;
              color: #666666;
            }
            .d {
              background: #9F62EE;
              font-size: 10px;
              color: #fff;
              margin-left: 10px;
              padding: 3px 4px;
              line-height: 1;
              border-radius: 4px;
            }
          }
          .detail {
            font-size: 14px;
            color: #000000;
          }
        }
        .r {
          padding: 0 5px;
          img {
            width: 18px;
            height: 18px;
          }
        }
      }
      .chang {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 0 0;
        .l {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
          span {
            color: #999999;
            font-size: 13px;
          }
        }
        :deep(.van-button) {
          background: #fff;
          .van-button__text {
            color: #333333;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }
    .active {
      background: rgba(159, 98, 238, .1);
      .info {
        .top {
          .name,
          .tel {
            color: @theme;
          }
        }
        .detail {
          color: @theme;
        }
      }
    }
  }
  .btns {
    width: 100%;
    padding: 0 15px;
  }
</style>
