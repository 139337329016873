<template>
  <div class="indexbox">
    <nav-bar title="商品详情" left-arrow />
    <van-swipe class="swipebox" autoplay="5000" indicator-color="#9F62EE">
      <van-swipe-item v-for="(item, index) in banner" :key="index">
        <img :src="item.url" alt="">
      </van-swipe-item>
    </van-swipe>

    <div class="info">
      <p class="name">{{ info.name }}</p>
      <p class="price">{{ info.price }} <span>token</span> </p>
    </div>

    <div class="bodybox">
      <div class="tit">
        <img src="@/assets/img/xq@2x.png" alt="">
      </div>
      <div class="content htmlcontentbox" v-html="info.content"></div>
    </div>

    <van-goods-action placeholder>
      <van-goods-action-button text="立即购买" @click="show = true" />
    </van-goods-action>

    <van-popup v-model="show" position="bottom" round closeable>
      <div class="infobox">
        <div class="good">
          <img :src="goods_img" alt="">
          <div class="r">
            <p class="p">{{ info.price }}</p>
            <p class="n"></p>
          </div>
        </div>
        <div class="num">
          <span>购买数量</span>
          <van-stepper v-model="num" :min="1" :max="info.stock" integer input-width="40px" button-size="23px" />
        </div>
        <van-button block @click="submit">立即购买</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        id: this.urlQuery().id,
        num: 1,
        goods_img: '',
        banner: [],
        info: {},
        show: false,
      }
    },
    created() {
      this.getdata()
    },
    methods: {
      getdata() {
        this.$get({
          loading: true,
          url: "/goods/detail",
          data: { id: this.id },
          success: (res) => {
            this.goods_img = res.data?.goods_img[0].url
            this.banner = res.data.slider
            this.info = res.data
          },
          tip: () => { },
        });
      },
      submit() {
        this.router(`subOrder?id=${this.id}&num=${this.num}`)
      }
    },
  }
</script>
<style lang="less" scoped>
  .indexbox {
    width: 100%;
    min-height: 100vh;
    padding-bottom: 10px;
    .swipebox {
      width: 100%;
      background-color: #fff;
      :deep(.van-swipe-item) {
        width: 100%;
        height: 100%;
        >img,
        >video {
          width: 100%;
          height: 375px;
          object-fit: contain;
        }
      }
      :deep(.van-swipe__indicators) {
        .van-swipe__indicator {
          width: 6px;
          height: 2px;
        }
        .van-swipe__indicator--active {
          width: 20px;
          height: 2px;
        }
      }
    }
    .info {
      width: 100%;
      background-color: #fff;
      padding: 12px 15px;
      margin-bottom: 10px;
      .name {
        width: 100%;
        color: #0A0A0A;
        font-size: 17px;
        font-weight: 550;
        line-height: 1.3;
        margin-bottom: 10px;
      }
      .price {
        width: 100%;
        color: @theme;
        font-size: 20px;
        font-weight: 550;
        line-height: 1.4;
        span {
          font-size: 14px;
        }
      }
    }
    .bodybox {
      width: 100%;
      padding-bottom: 10px;
      background-color: #fff;
      .tit {
        width: 100%;
        text-align: center;
        padding: 10px;
        img {
          width: 96px;
          height: 21px;
        }
      }
      .content {
        width: 100%;
        color: #333333;
        font-size: 14px;
        line-height: 1.4;
      }
    }
    :deep(.van-goods-action) {
      background: none;
    }
    .infobox {
      width: 100%;
      padding: 15px 15px 30px;
      .good {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        img {
          width: 80px;
          height: 80px;
          border-radius: 4px;
          object-fit: cover;
        }
        .r {
          width: 75%;
          padding-left: 4px;
          .p {
            width: 100%;
            color: @theme;
            font-size: 16px;
            font-weight: 550
          }
          .n {
            width: 100%;
            color: #999999;
            font-size: 10px;
            margin-top: 2px;
          }
        }
      }
      .num {
        width: 100%;
        padding: 20px 0 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #333333;
        font-size: 14px;
        font-weight: 600;
        :deep(.van-stepper) {
          display: flex;
        }
      }
    }
  }
</style>
