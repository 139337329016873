<template>
  <div class='indexbox'>
    <nav-bar title="提交订单" left-arrow />
    <div class="bodybox">
      <div class="addres" @click="router('address')">
        <img src="@/assets/img/dzz@2x.png" alt="">
        <div class="info" v-if="address?.id">
          <p><span>{{ address.name }}</span> {{ address.mobile }}</p>
          <p>{{ address.area.name_path }}-{{ address.detail }}</p>
        </div>
        <div class="info" v-else>
          <p> <span>请添加收货地址</span> </p>
        </div>
        <img src="@/assets/img/more@2x.png" alt="">
      </div>
      <div class="good">
        <img :src="goods_img" alt="">
        <div class="right">
          <p class="name two">{{ info.name }}</p>
          <div class="price">
            <p class="p">{{ info.price }}</p>
            <p>x{{ num }}</p>
          </div>
        </div>
      </div>
      <div class="infos">
        <div class="pay">
          <span>数量</span>
          <span>x{{ num }}</span>
        </div>
        <div class="pay">
          <span>订单金额</span>
          <p class="p">{{ total_price }}<span>token</span></p>
        </div>
      </div>
      <div class="payobx">
        <p class="t">支付方式</p>
        <div class="pays">
          <img src="@/assets/img/xxjf@2x.png" alt="">
          <span>平台币</span>
          <img src="@/assets/img/xz@2x.png" alt="">
        </div>
        <van-field v-model="password" clearable type="password" maxlength="6" label="资金密码" placeholder="请输入资金密码"
          :border="false" />
      </div>
    </div>
    <div class="footbox van-goods-action">
      <p class="one">合计：<span>{{ total_price }}</span><span>token</span></p>
      <van-button @click="onSubmit" :loading="loading">提交订单</van-button>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        id: this.urlQuery().id,
        num: this.urlQuery().num,
        goods_img: '',
        address: {},
        info: {},
        total_price: 0,
        password: '',
        loading: false,
      }
    },
    created() {
      this.getdata()
    },
    methods: {
      getdata() {
        this.$get({
          loading: true,
          url: "/goods/detail",
          data: { id: this.id },
          success: (res) => {
            this.goods_img = res.data.goods_img[0].url
            this.info = res.data
            this.total_price = Number(Number(this.info.price) * Number(this.num)).toFixed(2)
          },
          tip: () => { },
        });

        this.$get({
          loading: true,
          url: "/memberAddress/list?page=1",
          success: (res) => {
            if (res.data.data.length == 0) {
              this.$dialog.confirm({
                title: '提示',
                message: '请先添加收货地址',
              }).then(() => {
                this.router('editaddres')
              }).catch(() => { });
            } else {
              this.address = res.data.data[0]
            }
          },
          tip: () => { },
        });
      },
      onSubmit() {
        if (!this.address?.id) return this.$toast('请添加收货地址')
        if (!this.password) return this.$toast('请输入资金密码')
        this.loading = true
        this.$post({
          loading: true,
          url: "/order/create",
          data: {
            goods_id: this.id,
            num: this.num,
            address_id: this.address.id,
            password: this.password,
          },
          success: (res) => {
            this.$toast({
              message: '购买成功',
              onClose: () => {
                this.loading = false
                this.$router.replace('mine')
              }
            })
          },
          tip: () => {
            this.loading = false
          },
        });
      }
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    min-height: 100vh;
    padding-bottom: 40px;
    .bodybox {
      width: 100%;
      padding: 15px;
      >div {
        width: 100%;
        background: #fff;
        border-radius: 6px;
        margin-bottom: 15px
      }
      .addres {
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          &:nth-of-type(1) {
            width: 22px;
            height: 22px;
          }
          &:nth-of-type(2) {
            width: 14px;
            height: 14px;
          }
        }
        .info {
          width: 83%;
          p {
            width: 100%;
            color: #000000;
            font-size: 13px;
            line-height: 1.5;
            &:nth-child(1) {
              color: #666666;
              font-size: 12px;
              span {
                color: #000000;
                font-size: 16px;
                font-weight: 600;
                margin-right: 10px;
              }
            }
          }
        }
      }
      .good {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 85px;
          height: 85px;
          object-fit: cover;
          border-radius: 6px;
        }
        .right {
          width: 70%;
          min-height: 88px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .name {
            width: 100%;
            color: #000000;
            font-size: 14px;
            font-weight: 600;
          }
          .price {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
              color: #999999;
              font-size: 12px;
            }
            .p {
              color: @theme;
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
      .infos {
        padding: 5px 10px;
        border-radius: 6px 6px 0 0;
        margin-bottom: 0;
        .pay {
          padding: 10px 0 3px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #333333;
          font-size: 13px;
          span {
            &:nth-child(2) {
              font-weight: 600;
            }
          }
          .p {
            font-weight: 600;
            color: @theme;
            span {
              font-size: 11px;
            }
          }
        }
      }
      .payobx {
        padding: 5px 10px 10px;
        border-radius: 0 0 6px 6px;
        .t {
          color: #333333;
          font-size: 15px;
          font-weight: 600;
        }
        .pays {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0 4px;
          img {
            width: 20px;
            height: 20px;
          }
          span {
            flex: 1;
            padding: 0 10px;
            line-height: 1;
            color: #333333;
            font-size: 14px;
            font-weight: 600;
          }
        }
        :deep(.van-field) {
          padding: 20px 5px 5px;
        }
      }
    }
    .footbox {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      >p {
        width: 66%;
        padding: 5px 15px;
        text-align: right;
        font-size: 12px;
        span {
          color: @theme;
          font-weight: 600;
          &:nth-child(1) {
            font-size: 18px;
          }
          &:nth-child(2) {
            font-size: 14px;
          }
        }
      }
      :deep(.van-button) {
        width: 124px;
        height: 50px;
      }
    }
  }
</style>