<template>
  <div class='indexbox'>
    <nav-bar title="收银台" left-arrow />
    <div class="body">
      <div class="i">
        <p>需支付</p>
        <p>{{ price }} <span>token</span> </p>
      </div>
      <van-field v-model="password" clearable type="password" maxlength="6" label="资金密码" placeholder="请输入资金密码"
        :border="false" />

      <van-button @click="onSubmit" :loading="loading" block>立即下单</van-button>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        id: this.urlQuery().id,
        price: this.urlQuery().price,
        password: "",
        loading: false
      }
    },
    created() { },
    methods: {
      onSubmit() {
        if (!this.password) return this.$toast('请输入资金密码')
        this.loading = true
        this.$post({
          loading: true,
          url: "/investOrder/investDo",
          data: {
            id: this.id,
            password: this.password,
          },
          success: (res) => {
            this.password = ''
            this.loading = false
            this.$toast({
              message: '下单成功，请联系客服',
              onClose: () => {
                this.$router.back()
              }
            })
          },
          tip: () => {
            this.loading = false
          },
        });
      }
    },
  }
</script>
<style lang='less' scoped>
  .body {
    width: 100%;
    padding: 15px;
    .i {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 60px 10px;
      p {
        color: #030303;
        font-size: 15px;
        font-weight: 550;
        &:nth-child(2) {
          font-size: 26px;
          span {
            font-size: 16px;
          }
        }
      }
    }
    :deep(.van-field) {
      border-radius: 6px;
      margin-bottom: 180px;
    }
  }
</style>