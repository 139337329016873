<template>
  <div class='indexbox'>
    <nav-bar title="我的钱包" left-arrow />
    <div class="bodybox">
      <div class="head">
        <p>我的余额</p>
        <p class="n">{{ info.balance || 0 }}</p>
        <div class="b">
          <span @click="router('recharge')">充值</span>
          <span @click="router('withdrawal')">提现</span>
          <span @click="router('tran')">互转</span>
        </div>
      </div>
      <p class="tit">余额明细</p>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
        <div class="item" v-for="val in list" :key="val.id">
          <div class="l">
            <p>{{ val.remark }}</p>
            <p class="t">{{ val.created_at }}</p>
          </div>
          <p class="num">{{ val.amount }}</p>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        info: {},
        page: 1,
        list: [],
        finished: false,
        loading: false,
      }
    },
    created() {
      this.getinfo()
    },
    methods: {
      getinfo() {
        this.$get({
          loading: true,
          url: "/member/getMemberInfo",
          success: (res) => {
            this.info = res.data
          },
          tip: () => { },
        });
      },
      onLoad() {
        const page_num = this.page++
        this.$get({
          url: "/balanceLog/getList",
          data: {
            page: page_num,
            balance_field: 'balance',
          },
          success: (res) => {
            if (res.data && res.data.data.length == 0) {
              this.finished = true
            } else {
              if (page_num == 1) {
                this.list = res.data.data
              } else {
                this.list = [...this.list, ...res.data.data]
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
    },
  }
</script>
<style lang='less' scoped>
  .bodybox {
    width: 100%;
    padding: 15px 15px 40px;
    .head {
      width: 100%;
      padding: 19px 16px;
      background: url('../../../assets/img/bj@2x.png') no-repeat;
      background-size: 100% 100%;
      color: #fff;
      p {
        width: 100%;
        font-size: 13px;
      }
      .n {
        width: 100%;
        padding: 8px 0;
        font-size: 28px;
        font-weight: 550;
      }
      .b {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          width: 83px;
          height: 30px;
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #FFFFFF;
          text-align: center;
          line-height: 30px;
          font-size: 13px;
          &:nth-child(2) {
            margin: 0 20px;
          }
        }
      }
    }
    .tit {
      width: 100%;
      padding: 10px 0;
      font-size: 15px;
      font-weight: 550;
    }
    .item {
      width: 100%;
      background: #FFFFFF;
      border-radius: 6px;
      padding: 15px;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .l {
        width: 60%;
        p {
          font-size: 14px;
          line-height: 1.2;
        }
        .t {
          font-size: 12px;
          color: #8A8A8A;
          margin-top: 5px;
        }
      }
      .num {
        width: 39%;
        text-align: right;
        color: @theme;
        font-weight: 550;
        font-size: 16px;
      }
    }
  }
</style>