<template>
  <div class='indexbox'>
    <nav-bar v-if="!id" title="添加收货地址" left-arrow />
    <nav-bar v-else title="编辑收货地址" left-arrow right-text="删除" @click-right="deleteAddress" />

    <div class="bodybox">
      <div class="inp">
        <p class="t">收货人</p>
        <van-field v-model="name" clearable placeholder="请输入收货人姓名" :border="false" />
      </div>
      <div class="inp">
        <p class="t">手机号码</p>
        <van-field v-model="tel" type="tel" maxlength="11" clearable placeholder="请输入收货人手机号码" :border="false" />
      </div>
      <div class="inp">
        <p class="t">所在地区</p>
        <van-field v-model="addres_name" is-link readonly placeholder="请选择所在地区" :border="false" @click="show = true" />
      </div>
      <div class="inp">
        <p class="t">详细地址</p>
        <van-field v-model="address" clearable type="textarea" :rows="3" autosize placeholder="请输入详细地址：如道路、街道、楼号等"
          :border="false" />
      </div>

      <van-cell center title="设为默认收货地址">
        <template #right-icon>
          <van-switch v-model="checked" size="24" active-color="#C613F1" />
        </template>
      </van-cell>

      <van-button class="btn" block :loading="loading" @click="submit">保存</van-button>
    </div>

    <van-popup v-model="show" position="bottom">
      <van-area title="所在地区" :area-list="areaList" @cancel="show = false" @confirm="confirm" />
    </van-popup>
  </div>
</template>
<script>
  import { areaList } from '@vant/area-data';
  export default {
    data() {
      return {
        areaList,
        id: this.urlQuery().id,
        name: '',
        tel: '',
        province_id: '',
        city_id: '',
        area_id: '',
        addres_name: '',
        address: '',
        checked: false,
        show: false,
        loading: false,
      }
    },
    created() {
      if (this.id) {
        this.getdata()
      }
    },
    methods: {
      getdata() {
        this.$get({
          url: "/memberAddress/detail",
          data: {
            id: this.id,
          },
          loading: true,
          success: (res) => {
            this.name = res.data.name
            this.tel = res.data.mobile
            this.province_id = res.data.province_id
            this.city_id = res.data.city_id
            this.area_id = res.data.area_id
            this.addres_name = res.data.area.name_path
            this.address = res.data.detail
            this.checked = res.data.is_default == 1 ? true : false
          },
          tip: () => { },
        })
      },
      confirm(data) {
        this.province_id = data[0].code
        this.city_id = data[1].code
        this.area_id = data[2].code
        this.addres_name = `${data[0].name}-${data[1].name}-${data[2].name}`
        this.show = false
      },
      submit() {
        if (!this.name) this.$toast('请输入收货人姓名')
        if (!this.tel) this.$toast('请输入收货人手机号码')
        if (!this.addres_name) this.$toast('请选择所在地区')
        if (!this.address) this.$toast('请输入详细地址')
        this.loading = true
        const url = this.id ? "/memberAddress/edit" : "/memberAddress/add"
        const data = {
          name: this.name,
          mobile: this.tel,
          province_id: this.province_id,
          city_id: this.city_id,
          area_id: this.area_id,
          detail: this.address,
          is_default: this.checked ? 1 : 0,
        }
        if (this.id) { data.id = this.id }
        this.$post({
          url,
          data,
          loading: true,
          success: () => {
            this.name = ''
            this.tel = ''
            this.province_id = ''
            this.city_id = ''
            this.area_id = ''
            this.addres_name = ''
            this.address = ''
            this.checked = false
            this.$toast({
              message: '保存成功',
              onClose: () => {
                this.loading = false
                this.$router.go(-1)
              }
            })
          },
          tip: () => {
            this.loading = false
          }
        })
      },
      deleteAddress() {
        this.$dialog.confirm({
          title: '提示',
          message: '确认要删除此地址吗？',
        }).then(() => {
          this.$post({
            url: '/memberAddress/del',
            loading: true,
            data: { id: this.id },
            success: () => {
              this.$toast({
                message: '删除成功',
                onClose: () => {
                  this.$router.go(-1)
                }
              })
            },
            tip: () => { }
          })
        })
      },
    },
  }
</script>
<style lang='less' scoped>
  .bodybox {
    width: 100%;
    padding: 15px;
    .inp {
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .t {
        width: 35%;
        padding: 8px 0 0;
        color: #333333;
        font-size: 13px;
        font-weight: 600;
      }
      :deep(.van-field) {
        border-radius: 6px;
      }
    }
    :deep(.van-cell--center) {
      padding: 10px 0;
      border-radius: 6px;
      background: none !important;
      .van-cell__title {
        color: #333333;
        font-size: 13px;
        font-weight: 600;
      }
    }
    .btn {
      margin-top: 150px;
    }
  }
</style>