<template>
  <div class='indexbox'>
    <nav-bar title="我的订单" left-arrow />
    <van-tabs v-model="active" color="#9F62EE" title-inactive-color="#333333" sticky offset-top="46" @click="onClick">
      <van-tab :title="val.tit" v-for="val in topList" :key="val.id">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
          <div class="item" v-for="item in list" :key="item.id" @click="router(`orderdel?id=${item.id}`)">
            <div class="sn"> <span>订单编号: {{ item.order_sn }}</span>
              <span class="r">{{ ['', '待发货', '待收货', '已完成'][item.status] }}</span>
            </div>
            <div class="good">
              <img v-lazy="item.goods_image" alt="">
              <div class="info">
                <p class="n two">{{ item.goods_name }}</p>
                <div class="b">
                  <p class="p">{{ item.goods_price }}</p>
                  <p class="num">x{{ item.num }}</p>
                </div>
              </div>
            </div>
            <div class="btn" v-if="item.status == 2">
              <van-button size="small" round>确认收货</van-button>
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        active: 0,
        topList: [
          { tit: '全部', id: 0 },
          { tit: '待发货', id: 1 },
          { tit: '待确认', id: 2 },
          { tit: '已完成', id: 3 },
        ],
        page: 1,
        list: [],
        finished: false,
        loading: false,
      }
    },
    created() {
      if (this.session.get('orderIndex')) {
        this.active = Number(this.session.get('orderIndex'))
      }
    },
    methods: {
      onClick(index) {
        this.session.set('orderIndex', index)
        this.page = 1
        this.list = []
        this.finished = false
        this.onLoad()
      },
      onLoad() {
        const page_num = this.page++
        this.$get({
          url: "/order/list",
          data: {
            page: page_num,
            status: this.active,
          },
          success: (res) => {
            if (res.data && res.data.data.length == 0) {
              this.finished = true
            } else {
              if (page_num == 1) {
                this.list = res.data.data
              } else {
                this.list = [...this.list, ...res.data.data]
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    padding: 0 0 40px;
    :deep(.van-tabs) {
      .van-tab--active {
        font-weight: 600;
      }
    }
    :deep(.van-tabs__content) {
      padding: 10px 15px;
    }
    .item {
      width: 100%;
      padding: 15px;
      background: #FFFFFF;
      border-radius: 6px;
      margin-bottom: 10px;
      .sn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 13px;
        border-bottom: 1px solid #F5F5F5;
        span {
          font-size: 12px;
          color: #999999;
        }
        .r {
          color: @theme;
        }
      }
      .good {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 14px 0;
        img {
          width: 85px;
          height: 85px;
          object-fit: cover;
          border-radius: 5px;
        }
        .info {
          width: 68%;
          min-height: 78px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          line-height: 1;
          .n {
            width: 100%;
            color: #303133;
            font-size: 15px;
            font-weight: 550;
            line-height: 1.2;
          }
          .b {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            .p {
              color: @theme;
              font-size: 16px;
              font-weight: 550;
            }
            .num {
              color: #999999;
              font-size: 12px;
            }
          }
        }
      }
      .btn {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        :deep(.van-button) {
          padding: 10px 15px;
        }
      }
    }
  }
</style>