<template>
  <div class='indexbox'>
    <nav-bar title="理财订单" left-arrow />
    <van-tabs v-model="active" color="#9F62EE" title-inactive-color="#333333" sticky offset-top="46" @click="onClick">
      <van-tab :title="val.tit" v-for="val in topList" :key="val.id">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
          <div class="item" v-for="item in list" :key="item.id">
            <p class="n">{{ item.name }}</p>
            <div class="num">
              <div>
                <p>{{ item.price }}</p>
                <p>价格</p>
              </div>
              <span></span>
              <div>
                <p>{{ item.rate }}%</p>
                <p>收益率</p>
              </div>
              <span></span>
              <div>
                <p>{{ item.has_income }}token</p>
                <p>已收益</p>
              </div>
            </div>
            <div class="msg">
              <span>订单编号：</span>
              <span>{{ item.order_no }}</span>
            </div>
            <div class="msg">
              <span>购买时间：</span>
              <span>{{ item.created_at }}</span>
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        active: 0,
        topList: [
          { tit: '待支付', id: 2 },
          { tit: '收益中', id: 0 },
          { tit: '已结束', id: 1 },
        ],
        page: 1,
        list: [],
        finished: false,
        loading: false,
      }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
      onClick(index) {
        this.active = index
        this.page = 1
        this.list = []
        this.finished = false
        this.onLoad()
      },
      onLoad() {
        const page_num = this.page++
        this.$get({
          url: "/investOrder/list",
          data: {
            page: page_num,
            status: this.topList[this.active].id,
          },
          success: (res) => {
            if (res.data && res.data.data.length == 0) {
              this.finished = true
            } else {
              if (page_num == 1) {
                this.list = res.data.data
              } else {
                this.list = [...this.list, ...res.data.data]
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    padding: 0 0 40px;
    :deep(.van-tabs) {
      .van-tab--active {
        font-weight: 600;
      }
    }
    :deep(.van-tabs__content) {
      padding: 10px 15px;
    }
    .item {
      width: 100%;
      padding: 15px 0;
      background: url('../../../assets/img/kuang@2x.png') no-repeat;
      background-size: 100% 100%;
      border-radius: 6px;
      margin-bottom: 10px;
      .n {
        width: 100%;
        color: #000000;
        font-size: 16px;
        font-weight: 550;
        line-height: 1.2;
        padding: 0 15px;
      }
      .num {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #F5F5F5;
        padding: 5px 0;
        >div {
          width: 32%;
          padding: 10px 5px;
          >p {
            width: 100%;
            text-align: center;
            color: #999999;
            font-size: 13px;
            &:nth-child(1) {
              font-size: 16px;
              color: #333333;
              font-weight: 550;
              margin-bottom: 5px;
            }
          }
          &:nth-child(5) {
            p {
              &:nth-child(1) {
                color: @theme;
              }
            }
          }
        }
        span {
          width: 1px;
          height: 14px;
          background: #EAEAEA;
        }
      }
      .msg {
        width: 100%;
        padding: 10px 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          color: #999999;
          font-size: 13px;
          line-height: 1.2;
          &:nth-child(2) {
            color: #333333;
            font-weight: 550;
          }
        }
      }
    }
  }
</style>