<template>
  <div class='indexbox'>
    <nav-bar title="我的团队" left-arrow />
    <div class="body">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
        <div class="item" v-for="val in list" :key="val.id">
          <img v-lazy="val.head_img" alt="">
          <div class="info">
            <p>{{ val.account }}</p>
            <p>注册时间：{{ val.created_at }}</p>
          </div>
          <p class="r" v-if="val.is_effect == 1">
            <img src="@/assets/img/sm@2x.png" alt="">
            <span>有效</span>
          </p>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        page: 1,
        list: [],
        finished: false,
        loading: false,
      }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
      onLoad() {
        const page_num = this.page++
        this.$get({
          url: "/member/teamList",
          data: {
            page: page_num,
          },
          success: (res) => {
            if (res.data && res.data.data.length == 0) {
              this.finished = true
            } else {
              if (page_num == 1) {
                this.list = res.data.data
              } else {
                this.list = [...this.list, ...res.data.data]
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
    },
  }
</script>
<style lang='less' scoped>
  .body {
    width: 100%;
    padding: 15px;
    .item {
      width: 100%;
      background: #fff;
      border-radius: 6px;
      margin-bottom: 15px;
      padding: 15px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }
      .info {
        width: 70%;
        padding: 0 10px;
        p {
          width: 100%;
          color: #999999;
          font-size: 12px;
          &:nth-child(1) {
            color: #000000;
            font-size: 15px;
            font-weight: 550;
          }
        }
      }
      .r {
        background: rgba(159, 98, 238, .2);
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 5px;
        line-height: 1;
        img {
          width: 12px;
          height: 12px;
          margin-right: 2px;
        }
        span {
          color: @theme;
          font-size: 10px;
        }
      }
    }
  }
</style>