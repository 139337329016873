<template>
  <div class='indexbox'>
    <nav-bar title="提现" left-arrow />
    <div class="body">
      <p class="tit">钱包地址</p>
      <van-field v-model="address" type="textarea" rows="2" autosize placeholder="输入提现钱包地址" />

      <p class="tit">金额</p>
      <van-field v-model="number" type="number" placeholder="输入提现金额">
        <template #button>
          <p class="p">token</p>
        </template>
      </van-field>
      <p class="msg">
        <span>1xw={{ config.xw_price }}token</span>
        <span>手续费{{ config.fee_rate || 0 }}%</span>
      </p>
      <van-field v-model="getnumber" type="number" placeholder="实际到账(自动计算)">
        <template #button>
          <p class="p">XW</p>
        </template>
      </van-field>
      <p class="tit">资金密码</p>
      <van-field v-model="password" clearable type="password" maxlength="6" placeholder="请输入资金密码" :border="false" />

      <van-button :loading="loading" block @click="submit">确定</van-button>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        config: {},
        address: '',
        number: '',
        password: '',
        loading: false,
      }
    },
    computed: {
      getnumber: {
        get() {
          if (this.number) {
            if (this.config.fee_rate > 0) {
              return Number(Number(this.number) * Number(100 - Number(this.config.fee_rate)) / 100 / Number(this.config.xw_price)).toFixed(2)
            } else {
              return Number(Number(this.number) / Number(this.config.xw_price)).toFixed(2)
            }
          } else {
            return ''
          }
        },
      }
    },
    created() {
      this.getinfo()
    },
    methods: {
      getinfo() {
        this.$get({
          loading: true,
          url: "/withdraw/getConfig",
          success: (res) => {
            this.config = res.data
          },
          tip: () => { },
        });
      },
      submit() {
        if (!this.address) return this.$toast('请输入提现钱包地址')
        if (!this.number) return this.$toast('请输入提现金额')
        if (!this.password) return this.$toast('请输入资金密码')
        this.loading = true
        this.$post({
          loading: true,
          url: "/withdraw/withdrawDo",
          data: {
            address: this.address,
            amount: this.number,
            password: this.password,
          },
          success: (res) => {
            this.address = ''
            this.number = ''
            this.password = ''
            this.$toast('提现申请成功')
          },
          tip: () => { this.loading = false },
        })
      }
    },
  }
</script>
<style lang='less' scoped>
  .body {
    width: 100%;
    padding: 15px 15px 50px;
    .tit {
      width: 100%;
      font-size: 15px;
      font-weight: 550;
    }
    .msg {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      color: @theme;
      font-size: 12px;
      font-weight: 550;
    }
    .p {
      color: @theme;
      font-size: 15px;
      font-weight: 550;
    }
    :deep(.van-field) {
      background: #FFFFFF;
      border-radius: 6px;
      margin: 15px 0;
    }
    :deep(.van-button) {
      margin-top: 200px;
    }
  }
</style>