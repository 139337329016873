<template>
  <div class="indexbox">
    <div class="head">
      <nav-bar title="我的" bg-color="none" :fixed="false" />
      <div class="info" @click="router('user')">
        <img :src="httpPath + info.head_img" alt="">
        <p class=" n">{{ info.account }}</p>
      </div>
      <div class="add" @click="router('wealthIndex')">
        <div class="l">
          <img src="@/assets/img/qb@2x.png" alt="">
          <span>钱包</span>
        </div>
        <div class="r">
          <p>{{ info.balance || 0 }}<span>token</span></p>
          <van-icon name="arrow" size="12" color="#707070" />
        </div>
      </div>
    </div>
    <div class="body">
      <div class="navbox">
        <p class="t">我的订单</p>
        <div class="nav">
          <div @click="navlist('orderlist?type=0')">
            <img src="@/assets/img/qb@2x(1).png" alt="">
            <span>全部</span>
          </div>
          <div @click="navlist('orderlist?type=1')">
            <img src="@/assets/img/ddfh@2x.png" alt="">
            <span>待发货</span>
          </div>
          <div @click="navlist('orderlist?type=2')">
            <img src="@/assets/img/dsh@2x.png" alt="">
            <span>待收货</span>
          </div>
          <div @click="navlist('orderlist?type=3')">
            <img src="@/assets/img/wc@2x.png" alt="">
            <span>已完成</span>
          </div>
        </div>
      </div>
      <div class="navbox">
        <p class="t">其他应用</p>
        <div class="n">
          <router-link to="wealthlist" class="a">
            <img src="@/assets/img/licaijilu@2x.png" alt="">
            <span>理财记录</span>
            <van-icon name="arrow" size="14" color="#333333" />
          </router-link>
          <router-link to="address" class="a">
            <img src="@/assets/img/shouhuodizhi@2x.png" alt="">
            <span>收货地址</span>
            <van-icon name="arrow" size="14" color="#333333" />
          </router-link>
          <router-link to="share" class="a">
            <img src="@/assets/img/yaoqinghaoyou@2x.png" alt="">
            <span>邀请好友</span>
            <van-icon name="arrow" size="14" color="#333333" />
          </router-link>
          <router-link to="team" class="a">
            <img src="@/assets/img/wdtd@2x.png" alt="">
            <span>我的团队</span>
            <van-icon name="arrow" size="14" color="#333333" />
          </router-link>
          <router-link to="setting" class="a">
            <img src="@/assets/img/anquan@2x.png" alt="">
            <span>安全中心</span>
            <van-icon name="arrow" size="14" color="#333333" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        info: {},
      }
    },
    created() {
      this.getinfo()
    },
    methods: {
      getinfo() {
        this.$get({
          loading: true,
          url: "/member/getMemberInfo",
          success: (res) => {
            this.local.set('team_id', res.data.team_id)
            this.info = res.data
            if (res.data.is_pay_password == 0) {
              this.$dialog.confirm({
                title: '提示',
                message: '请先设置资金密码',
                showCancelButton: false,
              }).then(() => {
                this.router('changepay?type=1')
              })
            }
          },
          tip: () => { },
        });
      },
      navlist(index) {
        this.session.set('orderIndex', index)
        this.router('orderlist')
      }
    },
  }
</script>
<style lang="less" scoped>
  .indexbox {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(180deg, #FFFFFF 0%, #F8F8F8 100%);
    padding: 0 0 50px;
    .head {
      width: 100%;
      padding: 0 15px;
      background: url('../../assets/img/top@2x.png') no-repeat;
      background-size: 100% 129px;
      background-position: top center;
      .info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 66px;
          height: 66px;
          border-radius: 50%;
        }
        .n {
          width: 78%;
          color: #333333;
          font-size: 18px;
          font-weight: 550;
          line-height: 1.2;
        }
      }
      .add {
        width: 100%;
        background: #F8F8F8;
        border-radius: 6px;
        margin: 15px 0;
        padding: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .l {
          display: flex;
          align-items: center;
          img {
            width: 18px;
            height: 18px;
            margin-right: 5px;
          }
          color: #333333;
          font-size: 14px;
        }
        .r {
          width: 75%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          >p {
            color: @theme;
            line-height: 1.1;
            font-size: 15px;
            font-weight: 550;
            text-align: right;
            span {
              font-size: 13px;
            }
          }
        }
      }
    }
    .body {
      width: 100%;
      padding: 0 15px;
      .navbox {
        width: 100%;
        background: #FFFFFF;
        border-radius: 6px;
        margin-bottom: 15px;
        .t {
          width: 100%;
          padding: 10px 15px 5px;
          font-size: 15px;
          font-weight: 550;
        }
        .nav {
          width: 100%;
          padding: 10px 0 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          >div {
            width: 25%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 5px 15px;
            img {
              width: 36px;
              height: 26px;
            }
            span {
              font-size: 12px;
              margin-top: 4px;
            }
          }
        }
        .n {
          width: 100%;
          padding: 0 15px;
          .a {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 13px 0;
            border-bottom: 1px solid #F5F5F5;
            &:last-child {
              border-bottom: none;
            }
            img {
              width: 20px;
              height: 20px;
            }
            span {
              flex: 1;
              padding: 0 10px;
              font-size: 14px;
              font-weight: 550;
              color: #333333;
            }
          }
        }
      }
    }
  }
</style>