<template>
  <div class='indexbox'>
    <nav-bar title="订单详情" left-arrow />
    <div class="head" v-if="info.status == 1">
      <div class="l">
        <p>等待卖家发货</p>
        <p>请您耐心等待卖家发货哦~</p>
      </div>
      <img src="@/assets/img/ddfh@2x.png" alt="">
    </div>
    <div class="head" v-if="info.status == 2">
      <div class="l">
        <p>待收货</p>
        <p>您的商品正在运输中，注意接收~</p>
      </div>
      <img src="@/assets/img/dsh@2x.png" alt="">
    </div>
    <div class="head" v-if="info.status == 3">
      <div class="l">
        <p>订单已完成</p>
        <p>订单已完成~</p>
      </div>
      <img src="@/assets/img/wc@2x.png" alt="">
    </div>
    <div class="body">
      <div class="addres">
        <img src="@/assets/img/dzz@2x.png" alt="">
        <div class="info">
          <p><span>{{ info.name }}</span> {{ info.mobile }}</p>
          <p>{{ area.name_path }}-{{ info.address }}</p>
        </div>
      </div>
      <div class="addres" @click="copyData(info.express_no)">
        <img src="@/assets/img/wl@2x.png" alt="">
        <div class="info">
          <p><span>{{ info.express }}</span></p>
          <p>{{ info.express_no }} <em>复制</em></p>
        </div>
      </div>
      <div class="infos">
        <div class="good">
          <img :src="info.goods_image" alt="">
          <div class="right">
            <p class="name two">{{ info.goods_name }}</p>
            <div class="price">
              <p class="p">{{ info.goods_price }}</p>
              <p>x{{ info.num }}</p>
            </div>
          </div>
        </div>
        <div class="tp">
          <span>总金额</span>
          <span>{{ info.pay_money }}token</span>
        </div>
        <div class="t" @click="copyData(info.order_sn)">
          <span>订单编号：</span>
          <span>{{ info.order_sn }} <em> 复制</em></span>
        </div>
        <div class="t">
          <span>创建时间：</span>
          <span>{{ info.created_at }}</span>
        </div>
        <!-- <div class="t">
          <span>支付时间：</span>
          <span>{{ info.pay_at }}</span>
        </div> -->
        <div class="t" v-if="info.status == 3">
          <span>完成时间：</span>
          <span>{{ info.finish_at }}</span>
        </div>
      </div>
    </div>
    <van-goods-action v-if="info.status == 2">
      <div class="l"></div>
      <van-goods-action-button round text="确认收货" :loading="loading" @click="submit" />
    </van-goods-action>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        id: this.urlQuery().id,
        info: {},
        area: {},
        loading: false,
      }
    },
    created() {
      this.getdata()
    },
    methods: {
      getdata() {
        this.$get({
          url: "/order/detail",
          loading: true,
          data: {
            order_id: this.id,
          },
          success: (res) => {
            this.info = res.data
            this.area = res.data.area
          },
          tip: () => { },
        });
      },
      submit() {
        this.$dialog.confirm({
          title: '提示',
          message: '此订单确认要收货吗？',
        }).then(() => {
          this.$post({
            url: "/order/confirm",
            loading: true,
            data: {
              order_id: this.id,
            },
            success: (res) => {
              this.$toast({
                message: '收货成功',
                onClose: () => {
                  this.getdata()
                }
              })
            },
            tip: () => { },
          });
        }).catch(() => { })
      }
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    padding: 0 0 50px;
    .head {
      width: 100%;
      background: #fff;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .l {
        p {
          font-size: 13px;
          &:nth-child(1) {
            font-size: 15px;
            font-weight: 550;
          }
        }
      }
      img {
        width: 40px;
        height: 40px;
      }
    }
    .body {
      width: 100%;
      padding: 15px;
      .addres {
        width: 100%;
        background: #fff;
        border-radius: 6px;
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        img {
          width: 22px;
          height: 22px;
        }
        .info {
          width: 90%;
          p {
            width: 100%;
            color: #000000;
            font-size: 13px;
            line-height: 1.5;
            &:nth-child(1) {
              color: #666666;
              font-size: 12px;
              span {
                color: #000000;
                font-size: 16px;
                font-weight: 600;
                margin-right: 10px;
              }
            }
          }
          em {
            font-weight: 550;
            margin-left: 10px;
          }
        }
      }
      .infos {
        width: 100%;
        background: #fff;
        border-radius: 6px;
        padding: 10px 15px;
        .good {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          img {
            width: 85px;
            height: 85px;
            object-fit: cover;
            border-radius: 6px;
          }
          .right {
            width: 70%;
            min-height: 88px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .name {
              width: 100%;
              color: #000000;
              font-size: 14px;
              font-weight: 600;
            }
            .price {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              p {
                color: #999999;
                font-size: 12px;
              }
              .p {
                color: @theme;
                font-size: 16px;
                font-weight: 600;
              }
            }
          }
        }
        .tp {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 13px 0 10px;
          border-bottom: 1px solid #F8F8F8;
          margin-bottom: 10px;
        }
        .t {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 2;
          span {
            font-size: 13px;
            color: #000000;
            &:nth-child(2) {
              color: #999999;
              em {
                color: #000000;
                font-weight: 550;
              }
            }
          }
        }
      }
    }
    :deep(.van-goods-action) {
      .l {
        width: 70%;
      }
      .van-button {
        border-radius: 30px;
      }
    }
  }
</style>